export const restaurantData = [
         {
           title: "Boar Dough Tasting Room",
           neighborhood: "Agoura Hills",
           address: "5015 Cornell Rd unit g, Agoura Hills, CA 91301",
           cuisine: "American",
           service: "Takeout, dine-in",
           instagram: "@boardough",
           phone: "(818) 889-2387",
           website: "https://www.boardoughtastingroom.com/",
         },
         {
           title: "Wilson's Bbq Rib Shack",
           neighborhood: "Alhambra",
           address: "300 N El Molino St, Alhambra, CA 91801",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@wilsonsbbqribshack",
           phone: "(323) 457-0258",
           website: "https://www.wilsonsbbqribshack.com/",
         },
         {
           title: "Park Bench Gril",
           neighborhood: "Altadena",
           address: "2470 Lake Ave, Altadena, CA 91001",
           cuisine: "American",
           service: "Takeout",
           phone: "(626) 797-2740",
           website: "https://www.facebook.com/ParkBenchGrill/",
         },
         {
           title: "Little Red Hen Coffee Shop",
           neighborhood: "Altadena",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@thelittleredhencoffeeshop",
           phone: "(626) 791-1614",
           website: "https://little-red-hen-coffee-shop.hub.biz/",
         },
         {
           title: "Pizza of Venice ",
           neighborhood: "Altadena",
           cuisine: "Pizza",
           service: "Takeout, delivery",
           instagram: "@pizzaofvenice",
           phone: "(626) 765-9636",
           website: "https://www.pizzaofvenice.com/",
           merch: "Merch store",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "UEDF Fish & Chips",
           neighborhood: "Altadena",
           cuisine: "Seafood",
           service: "Takeout",
           phone: "(626) 794-0277",
           website: "https://www.facebook.com/UEDF-FISH-Chips",
         },
         {
           title: "Georgia's Restaurant",
           neighborhood: "Anaheim",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@georgiassoul",
           phone: "(714) 906-1900",
           website: "https://www.georgias-restaurant.com/",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Anaheim",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(714) 823-4130",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "Chicken N Chips ",
           neighborhood: "Atwater Village",
           cuisine: "Chicken sandwich",
           service: "Takeout, delivery",
           instagram: "@chickennchips",
           phone: "(323) 922-1070",
           website: "https://chickennchips.com/",
         },
         {
           title: "The Juice",
           neighborhood: "Atwater Village",
           cuisine: "Juice/drinks",
           service: "Takeout, delivery",
           instagram: "@thejuice_la",
           phone: "(323) 644-0250",
           website: "https://thejuicela.com/",
           merch: "Order here",
         },
         {
           title: "Keith's Kettle Corn",
           neighborhood: "Azusa",
           cuisine: "Popcorn",
           service: "Takeout, shipping",
           instagram: "@Keithskettlecorn",
           phone: "(626) 804-7999",
           website: "https://www.keithskettlecorn.com/",
           merch: "Online store",
         },
         {
           title: "Cafe Creole BH",
           neighborhood: "Baldwin Hills",
           cuisine: "Creole",
           service: "Takeout, delivery",
           instagram: "@cafecreolebh",
           phone: "(323) 389-8355",
           website: "http://www.eatatcafecreole.com/",
         },
         {
           title: "Harun Coffee",
           neighborhood: "Baldwin Hills",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@haruncoffee",
           phone: "(323) 815-9944",
           website: "https://harunintl.com/",
           merch: "Merch store",
         },
         {
           title: "Hotville Chicken",
           neighborhood: "Baldwin Hills",
           cuisine: "Hot chicken",
           service: "Takeout, delivery",
           instagram: "@hotvillechicken",
           phone: "(323) 792-4835",
           website: "https://www.hotvillechicken.com/",
         },
         {
           title: "Post & Beam",
           neighborhood: "Baldwin Hills",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@postandbeamla",
           phone: "(323) 299-5599",
           website: "https://postandbeamla.com/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "Southern Girl Desserts",
           neighborhood: "Baldwin Hills",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@southerngirldesserts",
           phone: "(323) 293-2253",
           website: "https://www.southerngirldesserts.com/",
         },
         {
           title: "T ‘N Irie Jerk Shack",
           neighborhood: "Baldwin Hills",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@tniriejerkshack",
           phone: "(310) 213-7616",
           website: "https://www.facebook.com/tniriesjerkshack/",
         },
         {
           title: "Yordanos Ethiopian Restaurant ",
           neighborhood: "Baldwin Hills",
           cuisine: "Ethiopian",
           service: "Takeout, delivery",
           instagram: "@yordanos_ethiopian_resturant",
           phone: "(323) 921-4215",
           website: "https://www.yelp.com/biz/yordanos",
         },
         {
           title: "2nd Healthy Eatery",
           neighborhood: "Bellflower",
           cuisine: "Deli, sandwiches",
           service: "Takeout",
           instagram: "@2ndhealthy",
           phone: "(562) 281-9374",
           website: "https://www.2ndhealthyeatery.com/home",
         },
         {
           title: "Hambone's Smokehouse",
           neighborhood: "Bellflower",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@hambones_smokehouse",
           phone: "(562) 920-5200",
           website: "http://hambonesbarandgrill.com/",
           merch: "Online store",
         },
         {
           title: "The Nest",
           neighborhood: "Bellflower",
           cuisine: "Breakfast",
           service: "Takeout, delivery",
           instagram: "@eatatthenest",
           phone: "(562) 804-5097",
           website: "https://eatnest.com/",
           merch: "Merch store",
           giftcard: "Gift cards (Toast)",
         },
         {
           title: "Beyond Vegan",
           neighborhood: "Boyle Heights",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@beyondveganeats",
           phone: "(323) 530-8694",
           website: "https://beyondveganlifestyle.com/",
           giftcard: "Gift cards",
         },
         {
           title: "ERM 4th Street",
           neighborhood: "Boyle Heights",
           cuisine: "Southern",
           service: "(temp closed)",
           instagram: "@erm4thstreet",
           phone: "(323) 406-2106",
           website: "www.eatrealordie.com",
           merch: "Merch store",
         },
         {
           title: "Silverback Coffee of Rwanda",
           neighborhood: "Boyle Heights",
           cuisine: "Coffee shop",
           service: "(Temp closed)",
           instagram: "@silverbackcoffee",
           phone: "(323) 685-5929",
           website: "https://silverbackcoffee.com/",
           merch: "Merch store",
         },
         {
           title: "Kings Deli",
           neighborhood: "Burbank",
           cuisine: "Deli, sandwiches",
           service: "Takeout, delivery",
           instagram: "@kingsdelila",
           phone: "(818) 561-4405",
           website: "https://www.kingsdeli.co/",
           giftcard: "Can be purchased in-store",
         },
         {
           title: "Lou, The French On The Block",
           neighborhood: "Burbank",
           cuisine: "Patisserie, coffee shop",
           service: "Takeout",
           instagram: "@louthefrenchontheblock",
           phone: "(747) 241-8205",
           website: "https://www.louthefrenchontheblock.com/",
         },
         {
           title: "JamaFo Jamaican Food Xpress",
           neighborhood: "Canoga Park",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@jamafoxpress",
           phone: "(747) 444-9260",
           website: "https://www.jamafoxpress.com/",
           giftcard: "Coupons available",
           other: "Download their app",
         },
         {
           title: "Thrive Doughnuts",
           neighborhood: "Canoga Park",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@thrivedoughnuts",
           website: "https://www.thrivedoughnuts.com/",
         },
         {
           title: "3Stakx Kitchen",
           neighborhood: "Carson",
           cuisine: "Southern, seafood",
           service: "Takeout",
           instagram: "@3stakxs_kitchen",
           phone: "(323) 474-4429",
           website: "https://www.3stakxkitchen.com/",
           merch: "Order here",
         },
         {
           title: "Belly’s Sliders & Wings",
           neighborhood: "Carson (Truck)",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@bellysslidersandwings",
           phone: "(424) 366-4572",
           website: "https://www.bellysslidersandwings.com/",
           merch: "Merch store",
         },
         {
           title: "Darrow’s New Orleans Grill ",
           neighborhood: "Carson",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@darrowsgril",
           phone: "(424) 570-0531",
           website: "https://www.darrowsneworleansgrill.com/",
           giftcard: "Gift cards (Gift Up)",
         },
         {
           title: "Family Fish Market",
           neighborhood: "Carson",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           phone: "(310) 515-535",
           website: "http://www.familyfishmarket.com/",
         },
         {
           title: "Fresh and Meaty Burgers",
           neighborhood: "Carson",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@freshandmeatyburgers",
           phone: "(424) 536-3046",
           website: "https://www.bestturkeyburgerlosangeles.com/",
         },
         {
           title: "Juice-C-Juice",
           neighborhood: "Carson",
           cuisine: "Juice/drinks",
           service: "Takeout, delivery",
           instagram: "@juicecjuice",
           phone: "(310) 324-8444",
           website: "https://www.gotjuices.com/",
           merch: "Order here",
           giftcard: "CashApp: $JuiceChemist",
           other:
             "Donate to their programs: Do 4 Self Entrepreneurship Academy / Eat to Live Reading Gardens / Send Book Bundles to your Young Readers",
         },
         {
           title: "Ken's Ice Cream",
           neighborhood: "Carson",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@kensparlor",
           phone: "(310) 324-7704",
           website: "http://kensicecream.net/",
         },
         {
           title: "M&M Soul Food",
           neighborhood: "Carson",
           cuisine: "Soul food",
           service: "Takeout, delivery",
         },
         {
           title: "R & R Soul Food Restaurant",
           neighborhood: "Carson",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@randrsoulfoods",
           phone: "(310) 715-6716",
           website: "https://www.randrsoulfood.com/",
         },
         {
           title: "27th Street Bakery",
           neighborhood: "Central Alameda",
           cuisine: "Bakery",
           service: "Takeout",
           instagram: "@27thstreetbakery",
           phone: "(323) 233-3469",
           website: "https://27thstreetbakery.com/",
         },
         {
           title: "Les Sisters",
           neighborhood: "Chatsworth",
           cuisine: "Cajun, BBQ",
           service: "Takeout",
           instagram: "@lessisters2014",
           phone: "(818) 998-0755",
           website: "http://www.lessisters.com/",
         },
         {
           title: "One876 Caribbean Restaurant",
           neighborhood: "Chatsworth",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@one876caribbeanrestaurant",
           phone: "(818) 678-9252",
           website: "https://one876caribbeanrestaurant.com/",
         },
         {
           title: "Alma's Cookies and Company Restaurant",
           neighborhood: "Compton",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@almas_cookies_and_company",
           phone: "(424) 381-4410",
           website: "https://almas-cookies-company-restaurant",
         },
         {
           title: "Billionaire Burger Boyz",
           neighborhood: "Compton",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@billionaireburgerboyz",
           phone: "(310) 554-4116",
           website: "https://www.billionaireburgerboyz.com/",
           merch: "Merch store",
         },
         {
           title: "Cliff's Texas Style Burritos",
           neighborhood: "Compton",
           cuisine: "Mexican",
           service: "Takeout, delivery",
           instagram: "@srcliffsburritos",
           phone: "(310) 609-2755",
           website: "http://www.cliffsburritos.com/",
         },
         {
           title: "Fish Bone Seafood",
           neighborhood: "Compton",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishbone_compton",
           phone: "(310) 933-8868",
           website: "https://fishboneseafood.com/",
         },
         {
           title: "Foster Bros",
           neighborhood: "Compton",
           cuisine: "Soul food, vegan",
           service: "Takeout",
           instagram: "@fosterbros_",
           phone: "213) 448-1203",
           giftcard: "Venmo/CashApp",
         },
         {
           title: "Fun Diggity",
           neighborhood: "Compton",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@fdfunnelcakes",
           phone: " (424) 242-5799",
           website: "https://fundiggity.business.site/",
           other: "COVID Relief Fund (GoFundMe)",
         },
         {
           title: "Happy Ice",
           neighborhood: "Compton (Truck)",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@happyice",
           phone: "(215) 800-3965",
           website: "https://happyicela.com/",
           merch: "Merch store",
         },
         {
           title: "JohnsonBrothersBBQ ",
           neighborhood: "Compton",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@johnsonbrothersbbq",
           phone: "(310) 933-8803",
           website: "https://www.johnsonbrothersbbq.com/",
         },
         {
           title: "M & T Donuts",
           neighborhood: "Compton",
           cuisine: "Dessert",
           service: "Takeout",
           instagram: "@m_t_donuts",
           phone: "(310) 505-4372",
           website: "https://m-and-t-donuts.square.site/",
         },
         {
           title: "Mom's Burger",
           neighborhood: "Compton",
           cuisine: "Burgers",
           service: "Takeout",
           instagram: "@momsburger310",
           phone: "(310) 632-6622",
           website: "https://www.yelp.com/biz/moms-burgers-compton",
         },
         {
           title: "Patria Coffee",
           neighborhood: "Compton",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@patriacoffee",
           phone: "(323) 513-4865",
           website: "https://www.patriacoffee.com/",
           merch: "Merch store",
           giftcard: "Gift cards (Squareup)",
           other: "Purchase a coffee subscription",
         },
         {
           title: "Plate Of Hue",
           neighborhood: "Compton",
           cuisine: "Vegan/vegetarian",
           service: "Catering",
           instagram: "@plateofhue",
           phone: "(323) 205-5283",
           website: "https://www.facebook.com/pg/plateofhue",
         },
         {
           title: "Pucker Up Lemonade Company",
           neighborhood: "Compton",
           cuisine: "Juice/drinks",
           service: "Takeout",
           instagram: "@puckeruplemonadeco",
           phone: "(562) 507-8177",
           website: "https://puckeruplemonadecompany.business.site/",
           merch: "Order here",
           giftcard: "CashApp: $Puckeruplemonade",
         },
         {
           title: "Smakk'D Refreshers & Teas",
           neighborhood: "Compton",
           cuisine: "Juice/drinks",
           service: "Takeout, delivery",
           instagram: "@smakkd_",
           phone: "424-785-4212",
           website: "https://www.smakkdrefreshers.com/",
           merch: "Order here",
         },
         {
           title: "The Original Taco Petes",
           neighborhood: "Compton",
           cuisine: "Mexican",
           service: "Takeout, delivery",
           instagram: "@the_original_tacopetes",
           phone: "(333) 569-5164",
           website: "https://originaltacopete.com/",
           merch: "Order here",
         },
         {
           title: "Trap Kitchen",
           neighborhood: "Compton (Truck)",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@therealtrapkitchen",
           phone: "(503) 442-0748",
           website: "https://www.trapkitchen.com/",
           other: "Purchase their cookbook",
         },
         {
           title: "Kick Ass Vegans",
           neighborhood: "Corona, Pop-Up",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@kickassvegans",
           website: "https://www.kickassvegans.com/",
           merch: "Order here",
         },
         {
           title: "BB's Cafe",
           neighborhood: "Covina",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@_bbscafe",
           phone: "(626) 888-4960",
           website: "https://www.yelp.com/biz/bbs-cafe-covina",
         },
         {
           title: "GS Cafe and Ethiopian Cuisine",
           neighborhood: "Covina",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(626) 332-0585",
           website: "https://gs-cafe-and-ethiopian-cuisine",
         },
         {
           title: "Jordan's Hot Dogs",
           neighborhood: "Crenshaw",
           cuisine: "Hotdogs, chili",
           service: "Takeout, drive-thru",
           instagram: "@jordanhotdogs",
           phone: "(323) 299-3647",
           website: "https://jordanshotdogsla.com/",
         },
         {
           title: "The Kobbler King",
           neighborhood: "Crenshaw",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@thekobblerking",
           phone: "(323) 731-9286",
           website: "http://thekobblerking.com/",
           merch: "Order here",
           other: "Catering available",
         },
         {
           title: "Who’s Hungry Caribbean & Catering",
           neighborhood: "Crenshaw",
           cuisine: "Caribbean",
           service: "Takeout, delivery",
           instagram: "@whoshungryla",
           phone: "(213) 610-4199",
           website: "https://www.whoshungrycatering.com/",
           other: "COVID Relief Fund (Moonclerk)",
         },
         {
           title: "Delvigne Croissant",
           neighborhood: "Culver City",
           cuisine: "Patisserie",
           service: "Takeout",
           instagram: "@delvignecroissant",
           phone: "213-326-4404",
           website: "https://www.delvignecroissant.com/",
         },
         {
           title: "Honey's Kettle",
           neighborhood: "Culver City",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@honeyskettle",
           phone: "(310) 202-5453",
           website: "http://honeyskettle.com/",
           merch: "Online store",
         },
         {
           title: "Industry Cafe + Jazz",
           neighborhood: "Culver City",
           cuisine: "Eritrean, Ethiopian",
           service: "Takeout",
           instagram: "@industryjazzcafe",
           phone: "(310)-202-6633",
           website: "https://industrycafela.com/",
         },
         {
           title: "Janga by Derrick's Jamaican Cuisine",
           neighborhood: "Culver City",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@jangabyderricks",
           phone: "(424) 603-4843",
           website: "http://jangabyderricks.com/",
           merch: "Online store",
         },
         {
           title: "JR's Barbeque",
           neighborhood: "Culver City",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@officialjrsbbq",
           phone: "(310) 837-6838",
           website: "http://jrs-bbq.com/",
         },
         {
           title: "Milk and Brookies",
           neighborhood: "Culver City ",
           cuisine: "Desserts",
           service: "Delivery",
           instagram: "@milk_and_brookies",
           website: "http://www.milkandbrookies.com/",
         },
         {
           title: "The Jerk Spot",
           neighborhood: "Culver City",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@jerkspotla",
           phone: "(310) 815-1160",
           website: "http://jerkspotla.com/index.html",
         },
         {
           title: "A Beautiful Life",
           neighborhood: "Downtown",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@abeautifullifejamaican",
           phone: "(213) 972-9900",
           website: "https://www.abeautifullifecafe.com/",
           merch: "Merch store",
         },
         {
           title: "Bo Daddy's BBQ",
           neighborhood: "Downtown",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@bodaddysbbqdtla",
           phone: "(213) 536-5210",
           website: "https://www.bodaddys.com/",
           merch: "Order here",
         },
         {
           title: "Bohemian House of Espresso + Chai",
           neighborhood: "Downtown",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@bohemianchai",
           phone: "(213) 628-3086",
           website: "https://www.bohemianchai.com/",
         },
         {
           title: "Boketto Cold Brew",
           neighborhood: "Downtown",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@bokettocoldbrew",
           phone: "310-490-5394",
           website: "https://www.bokettocoldbrew.com/",
           merch: "Online store",
         },
         {
           title: "Coffee by Kofi ",
           neighborhood: "Downtown",
           cuisine: "Coffee shop",
           service: "Delivery",
           instagram: "@coffeebykofi",
           phone: "(213) 949-7632",
           website: "https://www.coffeebykofi.com/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "Comfort LA ",
           neighborhood: "Downtown",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@comfort_la",
           phone: "(213) 537-0844",
           website: "https://www.eatcomfortla.com/",
           merch: "Merch store",
         },
         {
           title: "Divine Dips",
           neighborhood: "Downtown",
           cuisine: "Desserts, vegan",
           service: "Takeout",
           instagram: "@divinedipsdtla",
           phone: "(213) 265-7785",
           website: "http://divinedipsicecreme.com/",
           merch: "Find a retail store near you",
         },
         {
           title: "Funculo",
           neighborhood: "Downtown",
           cuisine: "Pasta",
           service: "Takeout",
           instagram: "@funcolodtla",
           phone: "(213) 325-3013",
           website: "https://downtownla.com/go/funculo",
         },
         {
           title: "Nimbus Coffee",
           neighborhood: "Downtown",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@nimbus_coffee_la",
           phone: "213-441-7309",
           website: "https://www.nimbuscoffeela.com/home",
           merch: "Merch store",
         },
         {
           title: "Poppy+Rose",
           neighborhood: "Downtown",
           cuisine: "Southern, diner",
           service: "Takeout, delivery",
           instagram: "@poppyandrosela",
           phone: "(213) 995-7799",
           website: "https://www.poppyandrosela.com/",
           merch: "Merch store",
         },
         {
           title: "Shaquille's Restaurant",
           neighborhood: "Downtown",
           cuisine: "Southern",
           service: "(Temp closed)",
           instagram: "@shaquillesla",
           phone: "(213) 746-7427",
           website: "https://www.shaquilles.com/",
         },
         {
           title: "Sugar Jones",
           neighborhood: "Downtown",
           cuisine: "Dessert",
           service: "Takeout, delivery",
           instagram: "@sugarjonessweetcreations",
           phone: "818-427-6324",
           website: "http://www.sweetsugarjones.com/",
           merch: "Merch store",
         },
         {
           title: "Vurger Guys",
           neighborhood: "Downtown (Truck)",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@vurgerguyz",
           phone: "(213) 298-1724",
           website: "https://www.vurgerguyz.com/",
           other: "Catering available",
         },
         {
           title: "Woodspoon",
           neighborhood: "Downtown",
           cuisine: "Brazilian",
           service: "Takeout, delivery",
           instagram: "@woodspoon",
           phone: "(213) 629-1765",
           website: "http://www.woodspoonla.com/",
           other: "Catering available",
         },
         {
           title: "Zac's Sweet Shop",
           neighborhood: "Downtown",
           cuisine: "Desserts",
           service: "Delivery, shipping",
           instagram: "@zacssweetshop",
           website: "https://zacssweetshop.com/",
         },
         {
           title: "Silverback Coffee of Rwanda",
           neighborhood: "Echo Park",
           cuisine: "Coffee shop",
           service: "(Temp closed)",
           instagram: "@silverbackcoffee",
           phone: "(323) 685-5929",
           website: "https://silverbackcoffee.com/",
           merch: "Merch store",
         },
         {
           title: "VanillaBlack Coffee.",
           neighborhood: "Echo Park",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@vanillablackla",
           website: "https://www.instagram.com/vanillablackla/",
         },
         {
           title: "Barbara Jean Soul Food",
           neighborhood: "Exposition Park",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@barbarajeaneats",
           phone: "(213) 419-9465",
           website: "http://barbarajeanla.com/",
           other: "Catering available",
         },
         {
           title: "Tracy's Belizean Restaurant",
           neighborhood: "Exposition Park",
           cuisine: "Belizean",
           service: "Takeout",
           phone: "(323) 735-2166",
           website: "https://www.yelp.com/biz/traceys-belizean",
         },
         {
           title: "South LA Cafe",
           neighborhood: "Exposition Park",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@southlacafe",
           phone: "(213) 260-0633",
           website: "https://www.southlacafe.com/",
           merch: "Online store",
           other: "COVID Relief Fund (GoFundMe) / Become a supporter",
         },
         {
           title: "Bludso's ",
           neighborhood: "Fairfax",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@bludsosbbq",
           phone: "(323) 931-2583",
           website: "https://www.barandque.com/",
           merch: "Merch store",
           giftcard: "Gift cards (Toast)",
           other: "National shipping available",
         },
         {
           title: "Fish Bone Seafood",
           neighborhood: "Gardena",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishboneseafoodgardena",
           phone: "(310) 532-7874",
           website: "https://fishboneseafood.com",
         },
         {
           title: "Fish Bone Seafood - Strawberry Square",
           neighborhood: "Gardena",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishbone_strawberry_square",
           phone: "(323) 814-2400",
           website: "https://fishboneseafood.com",
         },
         {
           title: "Fyre Bird Chicken",
           neighborhood: "Gardena",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@fyrebirdchicken_",
           phone: "(424) 269-1943",
           website: "https://www.yelp.com/biz/fyrebird-gardena",
           merch: "Order here",
         },
         {
           title: "Jay Bee's",
           neighborhood: "Gardena",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@jaybeesbbq",
           phone: "(310) 532-1064",
           website: "https://www.yelp.com/biz/jay-bees-bar-b-q",
         },
         {
           title: "Matthews Home Style Cooking",
           neighborhood: "Gardena",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@matthewshomestylecooking",
           phone: "(310) 817-5496",
           website: "https://matthewshomestylecooking.business.site/",
         },
         {
           title: "Mr Fries Man ",
           neighborhood: "Gardena",
           service: "Takeout",
           instagram: "@mr_fries_man",
           phone: "(424) 292-3616",
           website: "https://www.mrfriesman.com/",
           other: "Catering available",
         },
         {
           title: "Oh My Burger",
           neighborhood: "Gardena",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@ohmyburger",
           phone: "(310) 756-6522",
           website: "https://www.ohmyburgerla.com/",
           giftcard: "CashApp: $OhMyCatering",
         },
         {
           title: "Popbelli Deli",
           neighborhood: "Gardena",
           cuisine: "Deli, sandwiches",
           service: "Takeout",
           instagram: "@popbellideli",
           phone: "(562) 374-0658",
           website: "https://www.yelp.com/biz/pop-belli-deli",
         },
         {
           title: "The Soul Food Shack",
           neighborhood: "Gardena",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@thesoulfoodshack",
           phone: "323-923-8982",
           website: "https://soulfoodshackgarde.wixsite.com",
         },
         {
           title: "Yo-Way Deli",
           neighborhood: "Gardena",
           cuisine: "Deli, sandwiches",
           service: "Takeout",
           instagram: "@eatyoway",
           phone: "(424) 228-1101",
           website: "https://yowayeatery.com/",
         },
         {
           title: "Big Ant's BBQ",
           neighborhood: "Glassell Park",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@big_ants_bbq",
           phone: "(323) 987-0029",
           website: "https://www.bigantsbbq.com/",
         },
         {
           title: "Big Chicken",
           neighborhood: "Glendale",
           cuisine: "Southern",
           service: "(temp closed)",
           instagram: "@bigchickenshaq",
           phone: "(818) 245-6886",
           website: "https://www.bigchicken.com/",
           other: "25% off for first responders and frontline workers ",
         },
         {
           title: "The Reverse Orangutan",
           neighborhood: "Glendora",
           cuisine: "Coffee shop",
           service: "Delivery",
           instagram: "@thereverseorangutan",
           website: "https://www.thereverseorangutan.com/coffee/",
           merch: "Online store",
         },
         {
           title: "Dulan's Soul Food Kitchen ",
           neighborhood: "Gramercy Park ",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@dulanssoulfoodkitchen",
           phone: "(323) 418-8527",
           website: "https://www.dulans-sfk.com/",
         },
         {
           title: "Happy Ice",
           neighborhood: "Gramercy Park (Truck)",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@happyice",
           phone: "(215) 800-3965",
           website: "https://happyicela.com/",
           merch: "Merch store",
         },
         {
           title: "Main Kitchen Cafe",
           neighborhood: "Granada Hills",
           service: "Takeout, delivery, dine-in",
           instagram: "@mainkitchencafe",
           phone: "(747) 300-2433",
           website: "https://www.mainkitchencafe.com/",
           merch: "Order here",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "B&R's Old-Fashion Burgers",
           neighborhood: "Hawthorne ",
           cuisine: "Burgers",
           service: "Takeout",
           instagram: "@br_burgers",
           phone: "(310) 679-4774",
           website: "http://www.bandrburgers.com/",
           other: "Check their Instagram for the food truck location",
         },
         {
           title: "Busy Baking",
           neighborhood: "Hawthorne",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@busybaking12",
           phone: "(323) 919-3937",
           website: "https://www.busybaking.info/",
         },
         {
           title: "Barsha",
           neighborhood: "Hermosa Beach",
           service: "Takeout, delivery",
           instagram: "@barshalife",
           phone: "(424) 452-6266",
           website: "https://www.barshalife.com/",
           other: "Wine club",
         },
         {
           title: "Bloom & Plume",
           neighborhood: "Historic Filipinotown",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@bloomandplumecoffee",
           phone: "(213) 908-5808",
           website: "https://www.bloomandplumecoffee.com/",
           merch: "Merch store",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "Mo Better Burgers",
           neighborhood: "Hawthorne",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@mobettersb",
           phone: "(323) 531-1749",
           website: "https://mo-better-burgers-south-bay.business.site/",
         },
         {
           title: "Green Grotto Juice Bar",
           neighborhood: "Hollywood",
           cuisine: "Juice/drinks",
           service: "Takeout",
           instagram: "@greengrottojuicebar",
           phone: "(323) 701-2220",
           website: "https://www.yelp.com/biz/green-grotto-juice",
         },
         {
           title: "Harold's Chicken",
           neighborhood: "Hollywood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@haroldschickenla",
           phone: "(323) 645-7049",
           website: "https://www.haroldschickenla.com/",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Hollywood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(323) 466-7453",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "The Vtree",
           neighborhood: "Hollywood",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@thevtreehollywood",
           phone: "(202) 413-4083",
           website: "https://www.thevtreehollywood.com/",
         },
         {
           title: "Hambone's Smokehouse",
           neighborhood: "Huntington Beach",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@hambones_smokehouse",
           phone: "(714) 968-1261",
           website: "http://hambonesbarandgrill.com/",
           merch: "Online store",
         },
         {
           title: "Granny's Kitchen Southern Soul Food",
           neighborhood: "Huntington Park",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@grannys_kitchen_soulfood",
           phone: "(877) 462-6694",
           website: "https://www.yelp.com/biz/grannys-kitchen",
         },
         {
           title: "Brother’s Bar-B-Que",
           neighborhood: "Hyde Park",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@brothers_barbque",
           phone: "(323) 751-1050",
           website: "https://www.brotherbarbequecatering.com/",
         },
         {
           title: "CW & Chris Fish and Chicken",
           neighborhood: "Hyde Park",
           cuisine: "Seafood, chicken",
           service: "Takeout, delivery",
           phone: "(323) 750-9403",
           website: "https://cwandchris.wixsite.com/cwandchris",
         },
         {
           title: "Grilled Fraiche",
           neighborhood: "Hyde Park",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@grilledfraiche",
           phone: "(323) 389-8888",
           website: "https://www.instagram.com/grilledfraiche/",
         },
         {
           title: "Mama’s Chicken and Market",
           neighborhood: "Hyde Park",
           cuisine: "Grocery, chicken",
           service: "Takeout",
           phone: "(323) 292-9696",
           website: "http://www.mamaschicken.name/",
         },
         {
           title: "Papa John’s Pizza",
           neighborhood: "Hyde Park",
           cuisine: "Pizza",
           service: "Takeout, delivery",
           phone: "323-752-7272",
           website: "https://locations.papajohns.com/united-states",
         },
         {
           title: "Phatdaddy’s",
           neighborhood: "Hyde Park",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@_phatdaddys",
           phone: "(323) 792-4208",
           website: "https://www.facebook.com/phatdaddyss/",
         },
         {
           title: "Red's Flavor Table",
           neighborhood: "Hyde Park",
           cuisine: "Creole, soul food",
           service: "Takeout, delivery",
           instagram: "@redsflavortable",
           phone: "(323) 615-0234",
           website: "https://www.redsflavortable.com/",
         },
         {
           title: "Woody's BBQ",
           neighborhood: "Hyde Park",
           cuisine: "BBQ",
           service: "Takeout",
           phone: "(323) 294-9443",
           website: "http://www.woodysbarbquela.com/",
         },
         {
           title: "Aduke Nigerian Cuisine",
           neighborhood: "Inglewood",
           cuisine: "Nigerian",
           service: "Takeout, Delivery",
           instagram: "@adukecuisine",
           phone: "(213) 261-0737",
           website: "https://aduke-african-cuisne.business.site/",
         },
         {
           title: "Airport Royal Cuisine",
           neighborhood: "Inglewood",
           cuisine: "Ghanian",
           service: "Takeout",
           phone: "(562) 413-2294",
           website: "https://www.yelp.com/biz/airport-royal-cuisine",
         },
         {
           title: "Banadir Somali Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Somali",
           service: "Takeout, delivery",
           instagram: "@banadirsomalirestaurant",
           phone: "(310) 419-9900",
           website: "http://banadir-somali-restaurant.cafe",
         },
         {
           title: "Bayou Grille",
           neighborhood: "Inglewood",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@bayougrille",
           phone: "(310) 673-0824",
           website: "https://www.bayougrille.net/",
         },
         {
           title: "Big Daddy’s Kickin’ Chicken",
           neighborhood: "Inglewood",
           cuisine: "Southern, chicken",
           service: "Takeout, delivery",
           instagram: "@bigdaddyskickinchicken",
           phone: "(424) 309-9008",
           website: "https://www.facebook.com/pages/category",
         },
         {
           title: "Big Fish Market",
           neighborhood: "Inglewood",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@bigfishmarket_la",
           phone: "(323) 750-6000",
           website: "https://big-fish-market.business.site/",
         },
         {
           title: "Blessed Tropical Jamaican Cuisine",
           neighborhood: "Inglewood",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@blessedtropicaljamaicancuisin",
           phone: "(310) 330-0649",
         },
         {
           title: "Cobblers, Cakes & Kream",
           neighborhood: "Inglewood",
           cuisine: "Desserts",
           service: "Takeout, Delivery",
           instagram: "@cobblerscakesandkream",
           phone: "(323) 455-1224",
           website: "https://cobblerscakesandkream.com/",
         },
         {
           title: "Comfort LA ",
           neighborhood: "Inglewood",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@comfort_la",
           phone: "(424) 702-5138",
           website: "https://www.eatcomfortla.com/",
           merch: "Merch store",
         },
         {
           title: "Country Style Jamaican Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@csjrestaurant",
           phone: "(424) 227-6915",
           website: "https://www.yelp.com/biz/country-style-jamaican",
         },
         {
           title: "Dulan's Soul Food Kitchen",
           neighborhood: "Inglewood",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@dulanssoulfoodkitchen",
           phone: "(310) 671-3345",
           website: "https://www.dulans-sfk.com/",
         },
         {
           title: "E & J Seafood",
           neighborhood: "Inglewood",
           cuisine: "Seafood, soul food",
           service: "Takeout",
           instagram: "@e_and_j_seafood",
           phone: "(310) 673-0113",
           website: "https://www.yelp.com/biz/e-and-j-seafood",
         },
         {
           title: "Fish Bone Seafood",
           neighborhood: "Inglewood",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishboneseafoodinglewood",
           phone: "(310) 673-6452",
           website: "https://fishboneseafood.com",
         },
         {
           title: "Harriet's Cheesecakes Unlimited",
           neighborhood: "Inglewood",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@harriets_cheesecakes_unlimited",
           phone: "(310) 419-2259",
           website: "https://www.yelp.com/biz/a-cheesecakes",
         },
         {
           title: "Hilltop Coffee + Kitchen",
           neighborhood: "Inglewood",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@findyourhilltop",
           phone: "(323) 743-8100",
           website: "https://www.findyourhilltop.com/",
         },
         {
           title: "Jamz Creamery ",
           neighborhood: "Inglewood",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@jamzcreamery",
           phone: "(424) 351-8217",
           website: "https://jamzcreamery.com/",
         },
         {
           title: "Karuba's Yardy Kitchen ",
           neighborhood: "Inglewood",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@karubasyardykitchen",
           phone: "(424) 312-1055",
           website: "https://karubas-yardy-kitchen.business.site/",
         },
         {
           title: "Kenny Q's BBQ",
           neighborhood: "Inglewood",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@kennysqbbq",
           phone: "(424) 312-1105",
           website: "https://www.kennysqbbq.com/",
         },
         {
           title: "Lavender Blue Restaurant Lounge",
           neighborhood: "Inglewood",
           cuisine: "Southern",
           service: "Takeout",
           instagram: "@lavenderbluela",
           phone: "(424) 227-6712",
           website: "http://www.lavenderbluela.com/",
         },
         {
           title: "Lee's Caribbean Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@leescaribbeanrest",
           phone: "(310) 419-6550",
         },
         {
           title: "Little Belize Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Belizean",
           service: "Takeout, delivery",
           instagram: "@littlebelize",
           phone: "310-674-0696",
           website: "https://littlebelizerestaurant.com/",
           other: "Catering and hall rental available",
         },
         {
           title: "Mingles Tea Bar",
           neighborhood: "Inglewood",
           cuisine: "Coffee shop, juice/drinks, tea",
           service: "Takeout, delivery, limited dine-in",
           instagram: "@minglesteabar",
           phone: "(424) 704-2777",
           website: "https://minglesteabar.com/",
           merch: "Online store",
         },
         {
           title: "Ms. B's M&M Soul Food",
           neighborhood: "Inglewood",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@ms.bsmandmsoulfood",
           phone: "(310) 330-2697",
           website: "https://www.msbsmandmsoulfoodrestaurant.com/",
         },
         {
           title: "Nkechi African Cafe",
           neighborhood: "Inglewood",
           cuisine: "Nigerian",
           service: "Takeout, delivery",
           phone: "(323) 541-1265",
           website: "https://www.yelp.com/biz/nkechi-african-cafe",
         },
         {
           title: "Phat & Juicy Burgers",
           neighborhood: "Inglewood",
           cuisine: "Burgers",
           instagram: "@phatnjuicyburgers",
           phone: "(310) 673-4003",
           website: "https://www.yelp.com/biz/phat-and-juicy-burgers",
         },
         {
           title: "Queen of Sheba Ethiopian Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Ethiopian",
           service: "Takeout",
           instagram: "@queen_of_sheba_la",
           phone: "(424) 331-5626",
           website: "https://www.queenshebainglewood.com/",
         },
         {
           title: "Red's Flavor Table",
           neighborhood: "Inglewood",
           cuisine: "Creole, soul food",
           service: "Takeout, delivery",
           instagram: "@redsflavortable",
           phone: "(424) 331-5000",
           website: "https://www.redsflavortable.com/",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Inglewood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(310) 981 4141",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "Rusty Pot Cafe",
           neighborhood: "Inglewood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@rustypotcafe",
           phone: "(310) 412-5901",
           website: "https://rustypotcafe.com/",
         },
         {
           title: "Sip & Sonder",
           neighborhood: "Inglewood",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@sipandsonder",
           website: "https://www.sipandsonder.com/",
           merch: "Online store",
           giftcard: "PayPal: /SipandSonder",
           other: "Buy A Healthcare Worker A Cup Of Coffee (GoFundMe)",
         },
         {
           title: "Stuff I Eat",
           neighborhood: "Inglewood",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@stuffieat_inglewood",
           phone: "(310) 671-0115",
           website: "https://www.stuffieat.com/",
         },
         {
           title: "Sumptuous African Restaurant",
           neighborhood: "Inglewood",
           cuisine: "Nigerian",
           service: "Takeout, delivery",
           instagram: "@sumptuousafrican",
           phone: "(310) 654-3145",
           website: "http://sumptuousafricanrestaurant.com/",
           other: "Catering available",
         },
         {
           title: "Sweet Red Peach",
           neighborhood: "Inglewood",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@sweetredpeach",
           phone: "310-671-2253",
           website: "https://www.sweetredpeach.com/",
           merch: "Order here",
         },
         {
           title: "The Sammich Shop",
           neighborhood: "Inglewood",
           cuisine: "Deli, sandwiches",
           service: "Takeout, delivery",
           instagram: "@thesammicheshoppe",
           phone: "(424) 331-5128",
           website: "https://www.sammicheshoppe.com/",
           other: "Catering available",
         },
         {
           title: "The Serving Spoon",
           neighborhood: "Inglewood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@theservingspoon",
           phone: "(310) 412-3927",
           website: "https://theservingspoon.net/",
           other: "Pandemic Pay It Forward Program",
         },
         {
           title: "The Wood Urban Kitchen",
           neighborhood: "Inglewood",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@thewoodurbankitchen",
           phone: "(310) 466-9741",
           website: "https://www.yelp.com/biz/the-wood-urban-kitchen",
         },
         {
           title: "Veronica's Kitchen",
           neighborhood: "Inglewood",
           cuisine: "Nigerian",
           service: "Takeout",
           phone: "(310) 673-4890",
         },
         {
           title: "Wingopolis",
           neighborhood: "Inglewood",
           cuisine: "Hot wings, chicken",
           service: "Takeout, delivery",
           instagram: "@wingopolis",
           phone: "(310) 910-0291",
           website: "https://wingopolis.us/",
         },
         {
           title: "Woody's BBQ",
           neighborhood: "Inglewood",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@woodys_bbq_inglewood",
           phone: "(310) 672-4200",
           website: "http://www.woodysbarbquela.com/",
         },
         {
           title: "Your Bakery 1st",
           neighborhood: "Inglewood",
           cuisine: "Bakery",
           service: "Takeout, delivery",
           instagram: "@yourbakery1st",
           phone: "(310) 673-0744",
           website: "https://www.yourbakery1st.com/",
         },
         {
           title: "Harold and Belle's ",
           neighborhood: "Jefferson Park",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@haroldandbellesrestaurant",
           phone: "(323) 735-9023",
           website: "https://haroldandbelles.com/",
           giftcard: "Gift cards (Card Foundry)",
         },
         {
           title: "Revolutionario North African Tacos",
           neighborhood: "Jefferson Park",
           cuisine: "Algeria, Morocco, Tunisia, Mexican",
           service: "Takeout",
           instagram: "@chefzadi",
           phone: "(424)223-3526",
           website: "http://revolutionario.com/menu/",
           giftcard: "Gift cards (Square)",
         },
         {
           title: "Ribtown BBQ",
           neighborhood: "Jefferson Park (Truck)",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@ribtownbbq",
           phone: "(323) 360-7499",
           website: "http://ribtownbbq.com/",
         },
         {
           title: "Undergrind Cafe",
           neighborhood: "La Cienaga Heights",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@undergrindcafe",
           phone: "(310) 730-6392",
           website: "http://www.undergrindcafe.com/",
         },
         {
           title: "Doll Babee Cheesecakes",
           neighborhood: "Lakewood",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@dollbabeecheesecakes",
           phone: "(424) 261-2041",
           website: "http://dollbabeecheesecakes.com/",
         },
         {
           title: "M'Dears",
           neighborhood: "Lakewood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@mdears2450",
           phone: "(323) 759-2020",
           website: "https://www.mdears.net/",
           merch: "Online store",
         },
         {
           title: "R Kitchen Soul Food",
           neighborhood: "Lakewood",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@rkitchen__",
           phone: "(424) 296-5320",
         },
         {
           title: "Smakk'D Refreshers & Teas",
           neighborhood: "Lakewood",
           cuisine: "Juice/drinks",
           service: "Takeout, delivery",
           instagram: "@smakkd_",
           phone: "424-785-4212",
           website: "https://www.smakkdrefreshers.com/",
           merch: "Online store",
         },
         {
           title: "Taco Mell ",
           neighborhood: "Lakewood",
           cuisine: "Mexican",
           service: "(Temp closed)",
           instagram: "@tmclakewoodcenter",
           phone: "(562) 608-5689",
           website: "https://tacomell.net/",
         },
         {
           title: "Woody's BBQ",
           neighborhood: "Lakewood",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@woodysflorence",
           phone: "(323) 758-8303",
           website: "http://www.woodysbarbquela.com/",
         },
         {
           title: "Time 2 Grub",
           neighborhood: "Lancaster",
           cuisine: "Soul food",
           service: "Takeout, delivery, dine-in",
           instagram: "@time2grub661",
           phone: "(661) 468-0280",
           website: "https://www.yelp.com/biz/time-2-grub-lancaster-4",
         },
         {
           title: "Str8 Up Tacos",
           neighborhood: "La Palma",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@tacosstr8up",
           other: "Catering available",
         },
         {
           title: "5 Star Kitchen",
           neighborhood: "Leimert Park",
           service: "Takeout",
           instagram: "@5starkitchenla",
           phone: "(323) 316-8576",
           website: "https://www.yelp.com/biz/5-star-kitchen",
         },
         {
           title: "Ackee Bamboo",
           neighborhood: "Leimert Park",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@ackeebamboo",
           phone: "(323) 295-7275",
           website: "http://ackeebamboojacuisine.com/",
         },
         {
           title: "Alex's Fish Market",
           neighborhood: "Leimert Park",
           cuisine: "Seafood",
           service: "Takeout",
           phone: "(323) 298-4515",
           website: "https://www.yelp.com/biz/alex-fish-market",
         },
         {
           title: "Azla Vegan ",
           neighborhood: "Leimert Park",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@azlavegan",
           phone: "(213) 745-7455",
           website: "https://azlavegan.com/",
           merch: "Order here",
         },
         {
           title: "Baba’s Vegan Cafe",
           neighborhood: "Leimert Park",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@babasvegancafe",
           phone: "(310) 910-2942",
           website: "https://www.babasvegancafe.com/",
         },
         {
           title: "Castle's Catering",
           neighborhood: "Leimert Park",
           cuisine: "Southern, Creole",
           service: "Takeout, delivery",
           instagram: "@castlescatering/",
           phone: "(818) 724-4866",
           website: "https://www.castlescatering.com",
           merch: "Order here",
         },
         {
           title: "Dulan's on Crenshaw",
           neighborhood: "Leimert Park",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@dulansoncrenshaw",
           phone: "(323) 296-3034",
           website: "https://dulansoncrenshaw.com/",
         },
         {
           title: "Earle's On Crenshaw",
           neighborhood: "Leimert Park",
           cuisine: "Hot dogs, burgers",
           service: "Takeout, delivery",
           instagram: "@earlesoncrenshaw",
           phone: "(323) 299-2867",
           website: "https://earlesrestaurant.com/",
         },
         {
           title: "Harun Coffee",
           neighborhood: "Leimert Park",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@haruncoffee",
           phone: "(323) 815-9944",
           website: "https://harunintl.com/",
           merch: "Merch store",
         },
         {
           title: "Hot & Cool Cafe",
           neighborhood: "Leimert Park",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@hotandcoolcafe",
           phone: "(323) 903-6449",
           website: "https://hotandcoolcafe.com/",
         },
         {
           title: "Swift Cafe",
           neighborhood: "Leimert Park",
           cuisine: "Health food",
           service: "Takeout, delivery",
           instagram: "@theofficialswiftcafe",
           phone: "(323) 815-4418",
           website: "https://swift-la.com/",
         },
         {
           title: "Taco Mell ",
           neighborhood: "Leimert Park",
           cuisine: "Mexican",
           service: "Takeout",
           instagram: "@tacomellcatering",
           phone: "(844) 822-6635",
           website: "https://tacomell.net/",
         },
         {
           title: "Treme Kitchen",
           neighborhood: "Leimert Park",
           cuisine: "Desserts",
           service: "Delivery",
           instagram: "@tremekitchen",
           phone: "(323) 863-5738",
           website: "https://www.tremekitchen.com/",
           merch: "Merch store",
         },
         {
           title: "World Wide Tacos",
           neighborhood: "Leimert Park",
           cuisine: "Mexican",
           service: "Takeout, delivery",
           instagram: "@worldwidetacos",
           phone: "(323) 291-1500",
           website: "http://worldwidetacos.com/",
         },
         {
           title: "Annie's Soul Delicious",
           neighborhood: "Little Ethiopia",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@aesouldelicious",
           phone: "(323) 424-7402",
           website: "https://www.anniessouldelicious.com/",
           other: "Catering available",
         },
         {
           title: "Awash Restaurant",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(323) 939-3233",
           website: "http://awashrestaurantlosangeles.mybistro.online/",
         },
         {
           title: "Buna Ethiopian Restaurant & Market",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout, delivery",
           instagram: "@bunaethiopian",
           phone: "(323) 964-9731",
           website: "https://bunaethiopianmarket.com/",
         },
         {
           title: "Lalibela",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout, delivery",
           phone: "(323) 965-1025",
           website: "https://www.yelp.com/biz/lalibela-ethiopian",
         },
         {
           title: "Meals By Genet",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(323) 938-9304",
           website: "http://mealsbygenetla.com/",
         },
         {
           title: "Merkato",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout",
           instagram: "@merkatorestaurant",
           phone: "(323) 935-1775",
           website: "https://www.yelp.com/biz/merkato-ethiopian",
         },
         {
           title: "Messob Ethiopian Restaurant",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout, delivery",
           instagram: "@messob_ethiopian_restaurant",
           phone: "(323) 938-8827",
           website: "https://www.messob.com/",
         },
         {
           title: "Rahel",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(323)-937-8401",
           website: "http://rahelvegancuisine.com/",
         },
         {
           title: "Rosalind’s Ethiopian Restaurant",
           neighborhood: "Little Ethiopia",
           cuisine: "Ethiopian",
           service: "Takeout, delivery",
           instagram: "@rosalindsrestaurant",
           phone: "(323) 936-2486",
           website: "https://rosalindsla.com",
           other: "Catering available",
         },
         {
           title: "Taco Bar",
           neighborhood: "Little Ethiopia",
           cuisine: "Mexican",
           service: "Takeout, delivery",
           instagram: "@tacobarla",
           phone: "(323) 433-7000",
           website: "https://tacobarla.com/",
         },
         {
           title: "Ayda Ethiopian",
           neighborhood: "Loma Linda",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(909) 799-6700",
           website: "https://www.aydaethiopian.com/",
         },
         {
           title: "BBQ Chef",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@bbqchef17",
           phone: "(562) 612-1939",
           website: "https://www.yelp.com/biz/bbq-chef",
         },
         {
           title: "Brother's Keeper BBQ",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Pop-Up, catering ",
           instagram: "@brothers_keeperbbq",
           phone: "(562) 264-5955",
           website: "https://www.brotherskeeperbbq.com/",
         },
         {
           title: "Bundts on Melrose",
           neighborhood: "Long Beach",
           cuisine: "Bakery",
           service: "Takeout, delivery",
           instagram: "@bundtsonmelrose",
           phone: "(888) 928-6387",
           website: "http://www.bundtsonmelrose.com/",
           giftcard: "Catering/Corporate Gifts",
         },
         {
           title: "Chipper & Cheeky",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@chipperncheeky",
           phone: "(310) 817-0986",
           website: "https://www.chipperandcheeky.com/",
         },
         {
           title: "Derrick's On Atlantic",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@derricksonatlantic",
           phone: "(562)337-8131",
           website: "https://derricksonatlantic.com/",
           giftcard: "Gift cards (Toast)",
         },
         {
           title: "Devi's Donuts",
           neighborhood: "Long Beach",
           cuisine: "Desserts, vegan",
           service: "Takeout, delivery",
           instagram: "@devis.donuts",
           phone: "(949) 280-1859",
           website: "https://devisdonutsandsweets.com/#backtotop",
           merch: "Order here",
         },
         {
           title: "Georgia's Restaurant ",
           neighborhood: "Long Beach",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@georgiassoul",
           phone: "(562) 420-5637",
           website: "https://www.georgias-restaurant.com/",
         },
         {
           title: "Handy’s Smoke House Delicacies",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@handyssmokehouse",
           phone: "(562) 424-8300",
           website: "http://www.handyssmokehousemeats.com/",
         },
         {
           title: "J Looney's New Orleans Culture Food",
           neighborhood: "Long Beach",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@jlooneyscltrfd",
           phone: "(562) 612-3355",
           website: "https://www.yelp.com/biz/j-looneys-long-beach-2",
         },
         {
           title: "Ladie Kakes",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@myladiekakes",
           phone: "626-935-9293",
           website: "https://ladiekakes.square.site/",
           merch: "Order here",
         },
         {
           title: "Louisiana Charlie's",
           neighborhood: "Long Beach",
           cuisine: "Seafood, BBQ, Cajun",
           service: "Takeout, delivery",
           instagram: "@louisianacharlies",
           phone: "(562) 432-6737",
           website: "http://www.louisianacharlies.com/",
         },
         {
           title: "Mabel's Gourmet Pralines",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "(Temp closed)",
           instagram: "@mabelsgourmetpralines",
           phone: "(562) 682-7156",
           website: "https://www.yelp.com/biz/mabels-gourmet",
           other: "REPAIR THEIR STOREFRONT (GoFundMe)",
         },
         {
           title: "Mile High Cheesecake",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@milehigh_cheesecakes",
           phone: "(562) 448-2253",
           website: "https://www.facebook.com/milehighcheesecakes",
           merch: "Order here",
         },
         {
           title: "Northtown Bistro",
           neighborhood: "Long Beach",
           cuisine: "American",
           service: "(Temp closed)",
           instagram: "@northtownbistro",
           phone: "(562) 333-1858",
           website: "https://www.facebook.com/pg/northtownbistro",
         },
         {
           title: "O’Nells Comfort Kitchen",
           neighborhood: "Long Beach",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@onellscomfortkitchen",
           phone: "(562) 283-3434",
           website: "https://www.yelp.com/biz/o-nells-comfort",
         },
         {
           title: "Robert Earl’s BBQ",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@robertearlsbbq",
           phone: "(562) 726-1116",
           website: "http://robertearlsbbq.com/",
           other: "Catering available",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Long Beach",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(562) 437-8355",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "Royal Gourmet Cookies",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@royalgourmetcookies",
           phone: "(562) 208-5802 ",
           website: "https://royalgourmetcookies.com/",
           merch: "Order here",
         },
         {
           title: "Sal's Gumbo",
           neighborhood: "Long Beach",
           cuisine: "Cajun, BBQ",
           service: "Takeout, delivery",
           instagram: "@salsgumboshack",
           phone: "(562) 422-8100",
           website: "http://www.salsgumboshack.com/",
         },
         {
           title: "Savage Tacos Truck",
           neighborhood: "Long Beach (Truck)",
           cuisine: "Mexican",
           service: "Takeout",
           instagram: "@savagetacostruck",
           phone: "(310) 940-2727",
         },
         {
           title: "Soul Food Renaissance",
           neighborhood: "Long Beach",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@soulfoodrenaissance",
           phone: "(562) 422-3606",
           website: "https://soulfoodrenaissance.business.site/",
           merch: "Order here",
         },
         {
           title: "Tanjee Bakes",
           neighborhood: "Long Beach",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@tanjeebakes",
           phone: "(310) 756-8056",
           website: "https://www.tanjeebakes.com/",
         },
         {
           title: "The Boujie Crab",
           neighborhood: "Long Beach",
           cuisine: "Seafood, Cajun",
           service: "Takeout",
           instagram: "@boujiecrab",
           phone: "(562) 612-3311",
           website: "https://www.theboujiecrab.com/",
         },
         {
           title: "The Corner 10th’s BBQ",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@the_corner_10th_",
           phone: " (562) 434-0445",
           website: "https://www.thecorner10th.com/",
         },
         {
           title: "The Lion's Feast",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout, catering",
           instagram: "@thelionsfeast",
           phone: "(562) 3751051",
         },
         {
           title: "Twins Smoke House BBQ",
           neighborhood: "Long Beach",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@twinssmokehousebbq_",
           phone: "(562) 427-4800",
           website: "http://www.twinssmokehouse.com/",
         },
         {
           title: "Grilled Fraiche",
           neighborhood: "Manchester",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@grilledfraiche",
           phone: "(323) 744-2100",
           website: "https://www.instagram.com/grilledfraiche/",
         },
         {
           title: "Cafe Buna",
           neighborhood: "Marina Del Rey",
           cuisine: "Breakfast, diner",
           service: "Takeout",
           instagram: "@cafebuna_mdr",
           phone: "(310) 823-2430",
           website: "https://cafe-buna.business.site/",
         },
         {
           title: "Sky's Gourmet Tacos ",
           neighborhood: "Marina Del Rey",
           cuisine: "Mexican, Cajun",
           service: "Takeout, delivery",
           instagram: "@skystacos",
           phone: "(424) 353-0295",
           website: "https://skystacos.com/mdr",
           giftcard: "Gift cards (Yiftee)",
           other: "Help Skys Gourmet Tacos Feed COVID-19 First Responders",
         },
         {
           title: "Larayia's Bodega",
           neighborhood: "MacArthur Park",
           cuisine: "Grocery, deli",
           service: "Takeout",
           instagram: "@larayiasbodega",
           phone: "(818) 357-9114",
           website: "https://larayias-bodega.business.site/",
           merch: "Merch store",
           giftcard: "Venmo/CashApp",
         },
         {
           title: "DD'z Treatz",
           neighborhood: "Mid-City",
           cuisine: "Desserts",
           service: "Takeout, delivery ",
           instagram: "@ddztreatz",
           phone: "(310) 845-6152",
         },
         {
           title: "Little Amsterdam Coffee",
           neighborhood: "Mid-City",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@little.amsterdam.coffee",
           phone: "(323) 452-9124",
           website: "https://littleamsterdamcoffee.net/",
           merch: "Merch store",
           other: "Free drinks for healthcare workers on Mondays and Fridays",
         },
         {
           title: "Melkam Ethiopian Restaurant",
           neighborhood: "Mid-City",
           cuisine: "Ethiopian",
           service: "Takeout",
           phone: "(323) 734-3182",
           website: "https://www.yelp.com/biz/melkam-ethiopian",
         },
         {
           title: "Natraliart Jamaican Restaurant",
           neighborhood: "Mid-City",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@natraliart",
           phone: "(323) 732-8865",
           website: "https://www.natraliartrestaurant.com/",
           other: "Catering available",
         },
         {
           title: "Phillips Bar-B-Que",
           neighborhood: "Mid-City",
           cuisine: "BBQ",
           service: "Takeout",
           phone: "(323) 731-4772",
           website: "https://www.yelp.com/biz/phillips-barbecue",
         },
         {
           title: "Simply D’Licious",
           neighborhood: "Mid-City",
           cuisine: "Southern, Creole",
           service: "Takeout, Delivery",
           instagram: "@simplydliciousla",
           phone: "(562) 326-6863",
           website: "https://simplydliciousla.com/",
         },
         {
           title: "Wi Jammin Cafe ",
           neighborhood: "Mid-City",
           cuisine: "Jamaican",
           service: "Takeout, Delivery",
           instagram: "@wijammincarribean",
           phone: "(323) 936-1039",
           website: "https://www.wijammincafe.com/",
         },
         {
           title: "Flavors From A Far ",
           neighborhood: "Mid-Wilshire",
           cuisine: "Rotating",
           service: "Takeout, delivery",
           instagram: "@flavorsfromafar.co",
           phone: "(714) 623 - 9420",
           website: "https://www.flavorsfromafar.co/",
           giftcard: "Gift cards (Squareup)/PayPal",
         },
         {
           title: "My Two Cents",
           neighborhood: "Mid-Wilshire",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@my2centsla",
           phone: "(323) 879-9881",
           website: "http://mytwocentsla.com/",
         },
         {
           title: "Pips on La Brea",
           neighborhood: "Mid-Wilshire",
           cuisine: "Soul food",
           service: "(Temp closed)",
           instagram: "@pipsonlabrea",
           phone: "323-954-7477",
           website: "https://www.pipsonlabrea.com/index.html",
           giftcard: "Gift cards",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Mid-Wilshire",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(323) 934-4405",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "Sky's Gourmet Tacos ",
           neighborhood: "Mid-Wilshire",
           cuisine: "Mexican, Cajun",
           service: "Takeout, delivery",
           instagram: "@skystacos",
           phone: "(323) 932-6253",
           website: "https://skystacos.com/los-angeles",
           giftcard: "Gift cards (Yiftee)",
           other: "Help Skys Gourmet Tacos Feed COVID-19 First Responders",
         },
         {
           title: "Stevie's Creole Cafe",
           neighborhood: "Mid-Wilshire",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@steviesonpico",
           phone: "(323) 413-2494",
           website: "http://www.steviescreolecafe.com/",
         },
         {
           title: "Tacos Negros ",
           neighborhood: "Mid-Wilshire",
           cuisine: "Soul food, Mexican",
           service: "Takeout, delivery",
           instagram: "@tacosnegrosla",
           website: "https://www.instagram.com/tacosnegrosla/",
         },
         {
           title: "D's Original Take Out Grill",
           neighborhood: "Morningside Park",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@chefdsoriginalcatering",
           phone: "323-971-3663",
           website: "https://www.dsogtakeoutgrill.com/",
           other: "Catering available",
         },
         {
           title: "Fish Bone Seafood",
           neighborhood: "Morningside Park",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishboneseafood",
           phone: "(323) 615-0041",
           website: "https://fishboneseafood.com",
         },
         {
           title: "Wings and Pot",
           neighborhood: "Morningside Park",
           cuisine: "American, chicken",
           service: "Takeout, delivery ",
           instagram: "@wingsandpot",
           phone: "(323) 920-7575",
           website: "https://www.yelp.com/biz/wings-and-pot",
         },
         {
           title: "Black Bottom Southern Kitchen",
           neighborhood: "North Hollywood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@blackbottomsouthernkitchen",
           phone: "(818) 856-8532",
           website: "https://blackbottomkitchen.com/",
           other: "Rewards club",
         },
         {
           title: "BlaqHaus NoHo",
           neighborhood: "North Hollywood",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@blaqhausnoho",
           phone: "818-332-0500",
           website: "https://www.restaurantnoho.com/",
         },
         {
           title: "The Original Coleys",
           neighborhood: "North Hollywood",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@the_original_coleys",
           phone: "(818) 261-7680",
           website: "https://www.yelp.com/biz/the-original-coley",
         },
         {
           title: "The Memphis Grill",
           neighborhood: "North Hollywood",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@thememphisgrill",
           phone: "818-738-9993",
           website: "https://www.thememphisgrill.com/",
         },
         {
           title: "Lee Esther's Creole & Cajun Cooking",
           neighborhood: "Palmdale",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@leeestherscreole",
           phone: "(661) 266-0000",
           website: "https://www.leeesthers.com",
           merch: "Online store",
         },
         {
           title: "Parrains Soul Food",
           neighborhood: "Paramount",
           cuisine: "Soul food",
           service: "Takeout, Delivery",
           instagram: "@parrains_soulfood",
           phone: "(562) 634-6050",
           website: "https://www.yelp.com/biz/parrains-soulfood",
         },
         {
           title: "Crustees",
           neighborhood: "View Park-Windsor Hills",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@crustees",
           phone: "(323) 815-9910",
           website: "https://www.crusteeseatery.com/",
         },
         {
           title: "Hilltop Coffee + Kitchen ",
           neighborhood: "View Park-Windsor Hills",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@findyourhilltop",
           phone: "(323) 815-9833",
           website: "https://www.findyourhilltop.com/",
         },
         {
           title: "T ‘N Irie Jerk Shack",
           neighborhood: "View Park-Windsor Hills",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@tniriejerkshack",
           phone: "(310) 213-7616",
           website: "https://www.facebook.com/tniriesjerkshack/",
         },
         {
           title: "Bonnie B's Smokin BBQ Heaven",
           neighborhood: "Pasadena",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@bonniebssmokinbbq",
           phone: "(626) 794-0132",
           website: "https://www.bonniebssmokin.com/",
         },
         {
           title: "Lêberry Bakery",
           neighborhood: "Pasadena",
           cuisine: "Bakery",
           service: "Takeout, delivery",
           instagram: "@leberrybakery",
           phone: "(626) 993-9898",
           website: "https://leberrybakery.com/",
           other: "Catering available",
         },
         {
           title: "My Place Cafe ",
           neighborhood: "Pasadena",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@myplacecafepas",
           phone: "(626) 797-9255",
           website: "https://www.yelp.com/biz/my-place-cafe",
         },
         {
           title: "Pasadena Fish Market",
           neighborhood: "Pasadena",
           cuisine: "Seafood",
           service: "Takeout",
           phone: "(626) 792-9233",
           website: "https://www.yelp.com/biz/pasadena-fish-market",
         },
         {
           title: "Perry's Joint",
           neighborhood: "Pasadena",
           cuisine: "Deli, sandwiches",
           service: "Takeout, delivery",
           instagram: "@perrysjoint",
           phone: "(626) 798-4700",
           website: "https://perrysjoint.com/",
         },
         {
           title: "Rodney's Ribs ",
           neighborhood: "Pasadena (Truck)",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@rodneysribs",
           phone: "(626) 818-7427",
           website: "http://rodneysribslosangeles",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "Pasadena",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(626) 791-4890",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "The Gourmet Cobbler Factory",
           neighborhood: "Pasadena",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@thegourmetcobblerfactory",
           phone: "(626) 795-1005",
           website: "https://cobblerfactorypasadena.com/",
         },
         {
           title: "Café Ruisseau",
           neighborhood: "Playa Vista",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@cafe_ruisseau",
           phone: "(323) 561-0223",
           website: "https://caferuisseau.com/",
         },
         {
           title: "Day Day's BBQ & Waffle House",
           neighborhood: "Pomona",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@daydaysbbqandwafflehse",
           phone: "(909) 622-3217",
           website: "http://days-bbq-and-waffle.top-cafes.com/",
         },
         {
           title: "Hilltop's Jamaican Market & Restaurant",
           neighborhood: "Pomona",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           phone: "(909) 629-6407",
           website: "http://www.hilltopsjamaican.com/",
         },
         {
           title: "J&J's BBQ & Fish",
           neighborhood: "Pomona",
           cuisine: "Southern",
           service: "Takeout",
           instagram: "@jjbbqfish",
           phone: "(909) 865-6700",
           website: "https://jjbbqfish.com/",
           merch: "Order here",
           other: "Catering available",
         },
         {
           title: "All Flavor No Grease",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Mexican",
           service: "Takeout",
           instagram: "@allflavornogrease",
         },
         {
           title: "Anjahles",
           neighborhood: "Pop-Up",
           cuisine: "Jamaican",
           service: "Takeout",
           instagram: "@anjahles",
           phone: "(323) 300-4054",
         },
         {
           title: "Antidote Eats",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Burgers",
           service: "Takeout",
           instagram: "@antidote.eats",
           phone: "(562) 977-3366",
         },
         {
           title: "Ashley Cunningham",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Catering",
           instagram: "@sobadash_",
           phone: "(818) 272-4502",
           website: "https://www.youtube.com/user/ashleyamari",
         },
         {
           title: "Baby Love Sweetery",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@babylovesweetery",
           phone: "(818) 600-2136",
           website: "https://www.babylovesweetery.com/",
           merch: "Order here",
         },
         {
           title: "Bakedt Gourmet Goods",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@bakedt_gourmet_goods",
           website: "https://www.facebook.com/bakedtgourmetgoods/",
         },
         {
           title: "Bent Did It",
           neighborhood: "Pop-Up",
           service: "Catering",
           instagram: "@bentdidit",
           phone: " 323-628-2856",
           website: "https://bentevents.com/",
           merch: "Order here",
           giftcard: "CashApp: $bentevents131",
         },
         {
           title: "Billionaire Burger Boyz",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Burgers",
           service: "Takeout",
           instagram: "@billionaireburgerboyztruckla",
           website: "https://www.billionaireburgerboyz.com/",
           merch: "Merch store",
         },
         {
           title: "Black Cat BBQ",
           neighborhood: "Pop-Up (Catering)",
           cuisine: "BBQ",
           service: "Delivery",
           instagram: "@blackcatbbqla",
           phone: "(213) 477-3676",
           website: "http://blackcatbbq.com/",
           merch: "Shirts available ($15)",
         },
         {
           title: "Bridgetown Roti ",
           neighborhood: "Pop-Up",
           cuisine: "Caribbean",
           service: "Takeout",
           instagram: "@bridgetown_roti",
           website: "https://www.instagram.com/bridgetown_roti/",
         },
         {
           title: "Camille's",
           neighborhood: "Pop-Up",
           service: "Catering",
           instagram: "@camillescaters",
           phone: "(818) 570-1734",
           website: "https://www.facebook.com/camillesbakes4u/",
         },
         {
           title: "Caribbean Gourmet ",
           neighborhood: "Pop-Up",
           cuisine: "Guyanese",
           service: "Takeout",
           instagram: "@caribbeangourmet",
           phone: "(323) 447-0118",
           website: "https://www.instagram.com/caribbeangourmet/",
           other: "Catering available",
         },
         {
           title: "Chef Kiki's Kitchen",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@chef_kikis_kitchen",
           phone: "(323) 861-1580",
           website: "https://www.yelp.com/biz/chef-kikis-kitchen",
         },
         {
           title: "Cobbler Mania ",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@cobblermania",
           phone: "(323) 316-4793",
           website: "https://www.instagram.com/cobblermania/?hl=en",
         },
         {
           title: "Coco Luv",
           neighborhood: "Pop-Up",
           cuisine: "Desserts, vegan",
           service: "Delivery, shipping",
           instagram: "@cocoluvcookies",
           website: "https://cocoluvcookies.com/",
           merch: "Order here",
         },
         {
           title: "Cravin Crab Cakes",
           neighborhood: "Pop-Up",
           cuisine: "Seafood",
           service: "Takeout",
           instagram: "@cravincrabcakes",
           phone: "(619) 307-1581",
           website: "https://cravincrabcakes.com/",
         },
         {
           title: "Crustees",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@crustees",
           phone: "(323) 815-9910",
           website: "https://www.crusteeseatery.com/",
         },
         {
           title: "de Porres Dinner Series ",
           neighborhood: "Pop-Up",
           cuisine: "Southern",
           service: "Delivery",
           instagram: "@de__porres",
           phone: "(646) 391-4529",
           website: "http://www.de-porres.com/",
           other: "Catering available",
         },
         {
           title: "Everlasting Barbeque",
           neighborhood: "Pop-Up",
           cuisine: "BBQ",
           service: "Catering",
           instagram: "@everlastingbbq",
           phone: "(323) 828-2660",
           website: "http://everlastingbbq.com/Home_Page.html",
         },
         {
           title: "Fleurs et sel",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout, delivery ",
           instagram: "@fleursetsel",
         },
         {
           title: "FourFortyFour",
           neighborhood: "Pop-Up",
           cuisine: "Southern",
           service: "Delivery, catering",
           instagram: "@fourfortyfourcatering",
           website: "https://www.fourfortyfourcatering.com/",
         },
         {
           title: "Fraiche Signatures",
           neighborhood: "Pop-Up",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@fraiche_signatures",
           website: "https://www.instagram.com/fraiche_signatures/",
         },
         {
           title: "Freaky Fruit Pizza",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout, delivery",
           instagram: "@freakyfruitpizza",
           phone: "(323) 922-8628",
           website: "https://freakyfruitpizza.com/",
           merch: "Order here",
         },
         {
           title: "Fresh Out the Fire Pizza",
           neighborhood: "Pop-Up (Catering)",
           cuisine: "Pizza",
           service: "Catering, delivery",
           instagram: "@freshoutthefirepizza",
         },
         {
           title: "Island Flavors Caribbean Cuisine",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Haitian",
           service: "Takeout",
           instagram: "@islandflavors_cc",
           phone: "818-477-8076 ",
           website: "https://www.yelp.com/biz/island-flavors-caribbean",
           other:
             "Donate to help create Island Flavor's first storefront (GoFundMe)",
         },
         {
           title: "Island To Table Patty Hut",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@island2tablepatty",
           phone: "(949) 300-1733",
           website: "https://ittpattyhut.com/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "King Kone Ice Cream",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@kingkonela",
           phone: "(323) 208-1403",
           website: "http://www.kingkonela.com/",
         },
         {
           title: "LA Grind Coffee & Tea Bar",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@lagrindcoffeeandteabar",
           phone: "(323) 475-7001",
           website: "https://lagrindcoffeeandteabar.com/",
           merch: "Online store",
         },
         {
           title: "Lettuce Feast",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Vegan/vegetarian",
           service: "Takeout",
           instagram: "@lettucefeastla",
           phone: "(424) 261-3659",
           website: "https://www.lettucefeast.online/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "Mack & Jane",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "(Temp closed)",
           instagram: "@mackandjane",
           website: "https://www.mackandjane.com/",
         },
         {
           title: "Melnificent Wingz",
           neighborhood: "Pop-Up",
           cuisine: "Chicken wings",
           service: "Takeout, delivery",
           instagram: "@melnificentwingz",
           phone: "(818) 378-4825",
           website: "https://www.facebook.com/melnificentwingz/",
         },
         {
           title: "Munchie Madness",
           neighborhood: "Pop-Up",
           cuisine: "Rotating",
           service: "Takeout",
           instagram: "@_munchiemadnes",
           website: "https://www.instagram.com/_munchiemadness/",
         },
         {
           title: "Nita's Corn Catering ",
           neighborhood: "Pop-Up (Catering)",
           cuisine: "Corn",
           service: "Catering",
           instagram: "@nitascorncatering01",
           phone: "(866) 631-6262",
         },
         {
           title: "Noisy Library",
           neighborhood: "Pop-Up",
           cuisine: "Jamaican",
           service: "Delivery",
           instagram: "@_noisylibrary",
           giftcard: "Venmo/PayPal: @NoisyLibrary53",
         },
         {
           title: "Onil Chibás Events",
           neighborhood: "Pop-Up",
           service: "Delivery, catering",
           instagram: "@onilchibas",
           phone: "(626) 818 3963",
           website: "https://www.chibasevents.com/wonderful",
           giftcard: "Venmo/CashApp",
         },
         {
           title: "Ounjé LA",
           neighborhood: "Pop-Up",
           service: "Catering",
           instagram: "@ounje_la",
         },
         {
           title: "Ozi's Kitchen",
           neighborhood: "Pop-Up",
           cuisine: "Jamaican",
           service: "Takeout, delivery ",
           instagram: "@oziskitchen",
         },
         {
           title: "Pastry by Jazymne ",
           neighborhood: "Pop-Up",
           cuisine: "Dessert",
           service: "Takeout, delivery",
           instagram: "@pastrybyjazmyne",
           other:
             "Donate to their Baking for the Frontline Heroes program (GoFundMe)",
         },
         {
           title: "Popcorn Heaven",
           neighborhood: "Pop-Up",
           cuisine: "Popcorn",
           service: "Takeout, delivery",
           instagram: "@popcornheavenca",
           phone: "(626) 775-7124",
           website: "http://www.popcornheavenca.com/",
           merch: "Order here",
         },
         {
           title: "Shad's New Cali Catering",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Southern",
           service: "Takeout",
           instagram: "@shadsnewcalicatering",
           website: "https://www.instagram.com/shadsnewcalicatering",
           other: "Check their Instagram for locations ",
         },
         {
           title: "Smok N Joe’s Chili",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Chili",
           service: "Takeout",
           instagram: "@smoknjoeschili",
           phone: "(310) 817-0112",
           website: "https://www.smoknjoeschilila.com/",
           giftcard: "Donation page",
         },
         {
           title: "Sugar and Spice",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Catering",
           instagram: "@sugarandspice_bbb",
           website: "https://bres-bistro.square.site/",
         },
         {
           title: "Sugah Rush Bar",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Delivery",
           phone: "(424) 570-5981",
         },
         {
           title: "The Bléu Kitchen",
           neighborhood: "Pop-Up (Truck)",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@bleukitchen",
           website: "https://www.instagram.com/bleukitchen/?hl=en",
           other: "Check their Instagram for locations ",
         },
         {
           title: "The BLVD Kitchen",
           neighborhood: "Pop-Up",
           service: "Takeout",
           instagram: "@theblvdkitchen",
           phone: "(844) 454-2583",
           website: "http://www.theblvdkitchen.com/",
           merch: "Order here",
           giftcard: "Gift cards (Squareup)",
           other: "Online store",
         },
         {
           title: "The Real Cake Baker ",
           neighborhood: "Pop-Up (Catering)",
           cuisine: "Desserts, cakes",
           service: "Delivery, shipping",
           instagram: "@realcakebaker",
           phone: "(323) 305-2482",
           website: "https://www.therealcakebakery.com/",
           merch: "Order here",
         },
         {
           title: "Tiffany Cakes Desserts",
           neighborhood: "Pop-Up",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@tiffycakes_desserts",
         },
         {
           title: "Tisket A Tasket",
           neighborhood: "Pop-Up (Catering)",
           cuisine: "Soul food",
           service: "Delivery",
           instagram: "@tisketatasketcatering",
           phone: "(323) 291-8021",
           website: "https://tisketatasketcatering.com/",
           merch: "Order here",
         },
         {
           title: "Voodoo Vegan",
           neighborhood: "Pop-Up",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@voodooveganfood",
           phone: "(310) 437-9632",
           website: "https://www.yelp.com/biz/voodoo-vegan",
         },
         {
           title: "Cake Buzzz",
           neighborhood: "Redondo Beach",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@cakebuzzla",
           phone: "(310) 793-2899",
           website: "https://cakebuzzla.com/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "Bree’s Cakes",
           neighborhood: "Reseda",
           cuisine: "Desserts",
           service: "Takeout",
           instagram: "@breescake",
           phone: "(805) 444-2733",
           website: "https://www.breescakes.com/",
         },
         {
           title: "Gram's Mission BBQ ",
           neighborhood: "Riverside",
           cuisine: "Southern, BBQ",
           service: "Takeout",
           instagram: "@gramsmissionbbq",
           phone: "(951) 782-8219",
           website: "gramsmissionbbq.com",
           other: "Catering available",
         },
         {
           title: "Munchies Diner",
           neighborhood: "Santa Ana",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@munchiesdiner",
           phone: "(657) 699-3076",
           website: "https://www.munchiesvegandiner.com/",
           merch: "Order here",
         },
         {
           title: "SCV Fish Market",
           neighborhood: "Santa Clarita",
           cuisine: "Seafood",
           service: "Takeout",
           instagram: "@scvfishmarket",
           phone: "(661) 481-0330",
           website: "https://scvfishmarket.net/",
         },
         {
           title: "The D.W. Cookie Co.",
           neighborhood: "Santa Clarita",
           cuisine: "Desserts",
           service: "Takeout",
           phone: "(661) 505-0781",
           website: "https://www.yelp.com/biz/the-dw-cookie-co",
         },
         {
           title: "Compton Vegan ",
           neighborhood: "Sawtelle",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@comptonvegan",
           phone: "(310) 596-6690",
           website: "https://comptonvegan.com/",
           merch: "Merch store",
         },
         {
           title: "Jackfruit Cafe",
           neighborhood: "Sawtelle",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@jackfruitcafe",
           phone: "(818) 481-4111",
           website: "https://jackfruitcafe.com/",
           giftcard: "Gift cards (Squareup)",
         },
         {
           title: "T&T Lifestyle",
           neighborhood: "Sawtelle",
           cuisine: "Ethiopian",
           service: "Takeout",
           instagram: "@tandtlifestyle",
           phone: "(310) 699-8642",
           website: "https://www.tandtlifestylelosangeles.com/",
         },
         {
           title: "Fat Tuesday ",
           neighborhood: "Sherman Oaks",
           cuisine: "Cajun, Creole",
           service: "Takeout, delivery",
           instagram: "@eatatfattuesday",
           phone: "(818) 208-9838",
           website: "https://www.eatatfattuesday.com/",
         },
         {
           title: "My Fish Stop",
           neighborhood: "Sherman Oaks",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@myfishstop",
           phone: "(888) 469-9980",
           website: "https://www.myfishstop.com/",
           other: "Restaurant Survival Fund (GoFundMe)",
         },
         {
           title: "Dirty South Soulfood",
           neighborhood: "South Gate",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@dirtysoulfood",
           phone: "(323) 537-8007",
           website: "https://www.dirtysoulfood.shop/",
         },
         {
           title: "Coffee Del Mundo",
           neighborhood: "South LA",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@coffeedelmundola",
           phone: "(323) 455-0285",
           website: "https://coffeedelmundo.com/",
         },
         {
           title: "Hall’s Krispy Krunchy",
           neighborhood: "South LA",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@hallskrispykrunchy",
           phone: "(323) 305-3059",
           website: "https://www.krispykrunchy.com/locations/",
         },
         {
           title: "Roscoe's Chicken and Waffles",
           neighborhood: "South LA ",
           cuisine: "Southern",
           service: "Takeout, delivery",
           instagram: "@roscoesofficial",
           phone: "(323) 752-6211",
           website: "https://www.roscoeschickenandwaffles.com/",
           merch: "Merch store",
         },
         {
           title: "Texas BBQ King",
           neighborhood: "South LA",
           cuisine: "BBQ",
           service: "Takeout, delivery ",
           instagram: "@texas_bbq_king",
           phone: "(323) 750-1064",
           website: "http://www.texasbbqking.com/",
           merch: "Merch store",
           other: "BBQ sauce available for purchase",
         },
         {
           title: "Urban Comfort Foods",
           neighborhood: "South LA ",
           cuisine: "Comfort food",
           service: "Catering",
           instagram: "@urbancomfortfoods",
         },
         {
           title: "Hyperion Public",
           neighborhood: "Silver Lake",
           cuisine: "Gastropub",
           service: "(Temp closed)",
           instagram: "@hyperionpublic",
           phone: "(323) 761-6440",
           website: "http://www.hyperionpublic.com/silver-lake",
           giftcard: "Gift cards (Toast)",
           other: "Catering available",
         },
         {
           title: "Phat Birds",
           neighborhood: "Silver Lake",
           cuisine: "Southern",
           service: "Takeout",
           instagram: "@phatbirds",
           phone: "(323) 943-9191",
           website: "https://phatbirds.com/",
           other: "Playlists",
         },
         {
           title: "Say Cheese",
           neighborhood: "Silver Lake",
           cuisine: "Cheese shop",
           service: "Takeout, delivery",
           instagram: "@saycheeselosangeles",
           phone: "(323) 665-0545",
           website: "http://www.saycheeselosangeles.com/",
           merch: "Order here",
           other: "Gift baskets available for purchase",
         },
         {
           title: "Fresh and Meaty Burgers",
           neighborhood: "Studio City",
           cuisine: "Burgers",
           service: "(Temp closed)",
           instagram: "@freshandmeatyburgers",
           phone: "818-927-4051",
           website: "https://www.bestturkeyburgerlosangeles.com/",
         },
         {
           title: "Sattdown Jamaican Grill",
           neighborhood: "Studio City",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@sattdownjamaicangrill",
           phone: "818-766-3696",
           website: "http://sattdownjamaicangrill.com/",
           giftcard: "COVID Relief Fund (Square)",
           other: "Donate to their Feeding Families program (Square)",
         },
         {
           title: "Uncle Andre’s BBQ",
           neighborhood: "Studio City",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@uncleandresbbq",
           phone: "(818) 763-8414",
           website: "https://www.yelp.com/biz/uncle-andres-bbq",
         },
         {
           title: "Ranchside Cafe",
           neighborhood: "Sylmar",
           cuisine: "American, Ethiopian",
           service: "Takeout, delivery, dine-in",
           instagram: "@ranch_side_cafe",
           phone: "(818) 834-0031",
           website: "http://www.ranchsidecafe.com/",
         },
         {
           title: "Fish Bone Seafood",
           neighborhood: "Tarzana",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@fishboneseafood",
           phone: "(818) 975-8083",
           website: "https://fishboneseafood.com",
         },
         {
           title: "Obet & Del’s Coffee",
           neighborhood: "Thai Town",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@obetandels",
           phone: "(213) 770-0133",
           website: "https://www.yelp.com/biz/obet-and-dels",
           merch: "Order here",
           giftcard: "Gift cards (Toast)",
         },
         {
           title: "Lotus Vegan",
           neighborhood: "Toluca Lake",
           cuisine: "Vegan/vegetarian",
           service: "Takeout, delivery",
           instagram: "@lotusvegan",
           phone: "​​727-549-1688",
           website: "http://lotusveganla.com/",
         },
         {
           title: "Pepper's Jamaican Cuisine",
           neighborhood: "Van Ness",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@peppersjamaicancuisine_",
           phone: "(323) 389-8053",
           other: "Catering available",
         },
         {
           title: "Jaliz East African Cuisine",
           neighborhood: "Van Nuys",
           cuisine: "Ugandan",
           service: "Takeout, delivery",
           phone: "(818) 5712188",
           website: "http://jalizcuisine.com/",
         },
         {
           title: "Mom's Haus",
           neighborhood: "Van Nuys",
           cuisine: "BBQ",
           service: "Takeout",
           instagram: "@eatmomshaus",
           phone: "(818) 786-1373",
         },
         {
           title: "Sweet Blessings",
           neighborhood: "Van Nuys",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@sweet_blessings_",
           phone: "(818) 285-8437",
           website: "https://www.sweetblessingsla.com/",
           other: "Recovery Fund (GoFundMe)",
         },
         {
           title: "Toto’s African Cuisine",
           neighborhood: "Van Nuys",
           cuisine: "Nigerian",
           service: "Takeout, delivery",
           instagram: "@totosafricancuisine",
           phone: "(818) 781-3015",
           website: "https://www.yelp.com/biz/totos-african-cuisine",
         },
         {
           title: "Chulita",
           neighborhood: "Venice",
           cuisine: "Mexican",
           service: "Takeout",
           instagram: "@chulitavenice",
           phone: "424.252.9886",
           website: "https://www.chulita.com/",
         },
         {
           title: "Neighbor LA",
           neighborhood: "Venice",
           cuisine: "Cocktail bar",
           service: "(Temp closed)",
           instagram: "@neighborla",
           phone: "(310) 314-3625",
           website: "www.neighborla.com",
         },
         {
           title: "Hawkin's House of Burgers",
           neighborhood: "Watts",
           cuisine: "Burgers",
           service: "Takeout, delivery",
           instagram: "@hawkinshob",
           phone: "(323) 563-1129",
           website: "http://www.hawkinsburgers.com/",
           merch: "Merch store",
         },
         {
           title: "Lee's Market House of Wings",
           neighborhood: "Watts",
           cuisine: "Grocery, soul food",
           service: "Takeout",
           instagram: "@leesmarket",
           phone: "(323) 566-3549",
           website: "https://www.facebook.com/Leesmarketcatering/",
         },
         {
           title: "Watts Coffee House",
           neighborhood: "Watts",
           cuisine: "Coffee shop",
           service: "Takeout, delivery",
           instagram: "@wattscoffeehouse",
           phone: "(323) 249-4343",
           website: "https://www.facebook.com/wherewe",
         },
         {
           title: "Alta Adams",
           neighborhood: "West Adams",
           cuisine: "Soul food",
           service: "Takeout",
           instagram: "@altarestaurant",
           phone: "(323) 571-4999",
           website: "https://www.altaadams.com/",
         },
         {
           title: "Adams Coffee Shop",
           neighborhood: "West Adams",
           cuisine: "Coffee shop",
           service: "Takeout",
           instagram: "@adamscoffeeshop",
           phone: "(323) 571-4111",
           website: "https://www.altaadams.com/new-page-3",
         },
         {
           title: "Belizean Fish Market",
           neighborhood: "West Adams",
           cuisine: "Seafood",
           service: "Takeout",
           phone: "(323) 732-5405",
           website: "https://www.yelp.com/biz/belizean",
         },
         {
           title: "Chef Marilyn's",
           neighborhood: "West Adams",
           cuisine: "Soul food",
           service: "Takeout, delivery",
           instagram: "@marilynchef",
           phone: "(323) 737-8101",
           website: "https://www.chefmarilyns2638.com/",
         },
         {
           title: "Just Turkey ",
           neighborhood: "West Adams",
           cuisine: "Burgers, BBQ",
           service: "Takeout, delivery",
           instagram: "@just_turkey_la",
           phone: "(323) 591-0277",
           website: "http://justturkeyla.com/",
           other: "Catering available",
         },
         {
           title: "Mel's Fish Shack",
           neighborhood: "West Adams",
           cuisine: "Seafood",
           service: "Takeout, delivery",
           instagram: "@melsfishla",
           phone: "(323) 732-6357",
           website: "https://melsfishshack.com/",
         },
         {
           title: "The Court Cafe",
           neighborhood: "Westchester",
           cuisine: "Southern",
           service: "Takeout",
           instagram: "@thecourtcafe_inc",
           phone: "(310) 431-4969",
         },
         {
           title: "Brandoni Pepperoni",
           neighborhood: "West Hollywood",
           cuisine: "Pizza",
           service: "Takeout",
           instagram: "@la_brandoni_pepperoni",
           phone: "(323) 306 - 4968",
           website: "https://www.brandoni-pepperoni.com/",
         },
         {
           title: "Wowo's BBQ",
           neighborhood: "Whittier",
           cuisine: "BBQ",
           service: "Takeout, delivery",
           instagram: "@wowosbbq",
           phone: "(310) 430-4763",
           website: "https://www.wowosbbq.com/",
           other: "Catering available",
         },
         {
           title: "La Louisanne",
           neighborhood: "Windsor Hills",
           cuisine: "Cajun, Creole",
           service: "Takeout",
           phone: "323-293-5073",
           website: "http://www.lalouisannela.com/",
         },
         {
           title: "Little Kingston Jamaican Restaurant",
           neighborhood: "Windsor Hills",
           cuisine: "Jamaican",
           service: "Takeout, delivery",
           instagram: "@jamaicaonthego",
           phone: "(323) 295-5596",
           website: "https://www.instagram.com/jamaicaonthego/",
         },
         {
           title: "Simply Wholesome",
           neighborhood: "Windsor Hills",
           cuisine: "Grocery, vegan",
           service: "Takeout, delivery",
           instagram: "@simplywholesome",
           phone: "(323) 294-2144",
           website: "https://www.simplywholesome.com/",
           merch: "Online store",
           other:
             "A Video Documentary on Community, Health and Nutrition in Black Los Angeles",
         },
         {
           title: "The Snoball Shop",
           neighborhood: "Windsor Hills",
           cuisine: "Dessert",
           service: "Takeout",
           instagram: "@snoballshop",
           phone: "(323) 292-3145",
           website: "http://www.snoballshop.com/",
         },
       ];